import { ZodSchema, z } from "zod";

export type DoubleEntryAccount = {
    id: number;
    company: number;
    type: string;
    code: string;
    name: string;
    description: string;
}


export type DoubleEntryAccountView = {
    id: number;
    type: string;
    code: string;
    name: string;
    description: string;
    company?: number;
}

export type DoubleEntryAccountRequest = {
    type: string;
    code: string;
    name: string;
    description: string;
    company?: number;
}

export type DoubleEntryAccountUpdate = {
    type: string;
    code: string;
    name: string;
    description: string;
}


export const DoubleEntrySchemaUpdate: ZodSchema<DoubleEntryAccountUpdate> = z.object({
    type: z.string(),
    code: z.string(),
    name: z.string(),
    description: z.string(),
})

export const DoubleEntryCreateSchema: ZodSchema<DoubleEntryAccountRequest> = z.object({
    type: z.string(),
    code: z.string(),
    name: z.string(),
    description: z.string(),
    company: z.number().optional()
})


export type DoubleEntryAccountItem = {
    id: number;
    company: number; 
    account: number;
    item: number; 
    type: string;
};
