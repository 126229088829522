import { ApiData, ApiResponse } from "@/types/Api";
import api from "./api";
import axios, { AxiosError, ResponseType } from "axios";
import { getUserToken } from "@/utils/localStorage";


class GenericApi {

    public static host: string = window.location.href
    public static baseUrl: string = new URL(this.host).origin + '/api'

    // public static baseUrl: string = 'http://192.168.43.15/api'
    // public static baseUrl: string = 'http://192.168.1.138/api'
    // public static baseUrl: string = 'http://192.168.0.102/api'
    // public static baseUrl: string = 'http://192.168.1.155/api'
    // public static baseUrl: string = 'http://192.168.100.9/api'
    // public static baseUrl: string = 'http://192.168.0.102/api'
    // public static baseUrl: string = 'http://192.168.100.8/api'
    // public static baseUrl: string = 'http://192.168.1.160/api'
    // public static baseUrl: string = 'http://192.168.100.11/api'

    static async get<T>(endPoint: string, newUrl?: string, callbackErr?: () => void): Promise<ApiResponse<T>> {

        const apiResponse: ApiResponse<T> = {
            data: null,
            error: null
        }

        try {
            const response = await api.get<T>(`${newUrl ?? this.baseUrl}${endPoint}`,
                {
                    headers: { 'Authorization': `Token ${getUserToken()}`, }
                });
            apiResponse.data = response.data;
            return apiResponse;
        } catch (error) {
            callbackErr?.();
            if (axios.isAxiosError(error)) {
                console.error('Request failed:', error.message);
                console.error('Status code:', error.response?.status);
                console.error('Response data:', error.response?.data);
                apiResponse.error = error;
                throw apiResponse;
            }
            apiResponse.error = error as Error;
            throw apiResponse;
        }
    }

    static async getById<T>(endPoint: string, id: number): Promise<ApiResponse<T>> {
        const apiResponse: ApiResponse<T> = {
            data: null,
            error: null
        }

        try {
            const response = await api.get<T>(`${this.baseUrl}${endPoint}/${id}`,
                {
                    headers: { 'Authorization': `Token ${getUserToken()}` }
                });
            apiResponse.data = response.data;
            return apiResponse;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Request failed:', error.message);
                console.error('Status code:', error.response?.status);
                console.error('Response data:', error.response?.data);
                apiResponse.error = error;
                throw apiResponse;
            }
            apiResponse.error = error as Error;
            throw apiResponse;
        }
    }

    static async post<T>(endPoint: string, data: ApiData | ApiData[], responseType?: ResponseType): Promise<ApiResponse<T>> {

        const apiResponse: ApiResponse<T> = {
            data: null,
            error: null
        }

        try {

            const response = await api.post<T>(`${this.baseUrl}${endPoint}`, data,
                {
                    headers: { 'Authorization': `Token ${getUserToken()}`, },
                    responseType: responseType ?? 'json'
                });
            apiResponse.data = response.data;
            return apiResponse;

        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Request failed:', error.message);
                console.error('Status code:', error.response?.status);
                console.error('Response data:', error.response?.data);
                apiResponse.error = error;
                throw apiResponse;
            }
            apiResponse.error = error as Error;
            throw apiResponse;
        }
    }

    static async put<T>(endPoint: string, data: ApiData): Promise<ApiResponse<T>> {

        const apiResponse: ApiResponse<T> = {
            data: null,
            error: null
        }


        try {
            const response = await api.put<T>(`${this.baseUrl}${endPoint}`, data,
                {
                    headers: { 'Authorization': `Token ${getUserToken()}`, }
                });
            apiResponse.data = response.data;
            return apiResponse;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Request failed:', error.message);
                console.error('Status code:', error.response?.status);
                console.error('Response data:', error.response?.data);
                apiResponse.error = error as Error;
                throw apiResponse;
            }
            apiResponse.error = error as Error;
            throw apiResponse;
        }
    }

    static async delete<T>(endPoint: string): Promise<ApiResponse<T>> {

        const apiResponse: ApiResponse<T> = {
            data: null,
            error: null
        }

        try {
            const response = await api.delete<T>(`${this.baseUrl}${endPoint}`,
                {
                    headers: { 'Authorization': `Token ${getUserToken()}`, }
                });
            apiResponse.data = response.data;
            return apiResponse;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Request failed:', error.message);
                console.error('Status code:', error.response?.status);
                console.error('Response data:', error.response?.data);
                apiResponse.error = error as Error;
                throw apiResponse;
            }
            apiResponse.error = error as Error;
            throw apiResponse;
        }
    }

    static async patch<T>(endPoint: string, data: ApiData): Promise<T | AxiosError | Error> {
        try {
            const response = await api.patch<T>(`${this.baseUrl}${endPoint}`, data,
                {
                    headers: { 'Authorization': `Token ${getUserToken()}`, }
                });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Request failed:', error.message);
                console.error('Status code:', error.response?.status);
                console.error('Response data:', error.response?.data);
                throw error;
            }
            throw error as Error;
        }
    }

}

export default GenericApi;