
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import themeSlice from "./slices/themeSlice";
import organizationSlice from "./slices/organizationSlice";
import companySlice from "./slices/companySlice";
import pageSlice from "./slices/pageSlice";
import permissionsSlice from "./slices/permissionsSlice";

const store = configureStore({
  reducer: {
    theme: themeSlice.reducer,
    organization: organizationSlice.reducer,
    company: companySlice.reducer,
    page: pageSlice.reducer,
    permissions: permissionsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;