import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiError, ApiResponse } from "@/types/Api";
import {
  CurrencyQueryKey,
  DoubleEntryAccountQueryKey,
  DoubleEntryTaxQueryKey,
  JournalEntryQueryKey,
} from "@/config/constants/QueryKeys";
import GenericApi from "@/api/genericApi";
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { DoubleEntryAccount } from "@/types/DoubleEntry";
import { FallbackPage, LoadingPage } from "@/pages";
import { DoubleEntryJournal, DoubleEntryJournalCreateRequest, DoubleEntryJournalView } from "@/types/Journal";
import { FormError, ValidationErrors } from "@/types/ValidationError";
import CustomLogger from "@/utils/CustomLogger";
import { useSnackBarAlert } from "@/hooks/useSnackbar";
import { AxiosError, isAxiosError } from "axios";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { Currency } from "@/types/Currency";
import React from "react";
import { DoubleEntryTax } from "@/types/DoubleEntryAccountTax";

type Pair = {
  debit_account: string;
  credit_account: string;
  debit: number;
  credit: number;
};

const JournalItem: React.FC = () => {
  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBarAlert();
  const selectedCompany = useSelector((state: RootState) => state.company.selectedCompany);
  const selectedPage = useSelector((state:RootState) => state?.page?.id)


  const [rows, setRows] = useState<Array<{
    journalNumber: string;
    type: string;
    date: string;
    documentNumber: string;
    description: string;
    currency: string;
    vat: string;
    pairs: Pair[];
  }>>([
    {
      journalNumber: "",
      type: "",
      date: "",
      documentNumber: "",
      description: "",
      currency: "",
      vat: "",
      pairs: [{ debit_account: "", credit_account: "", debit: 0, credit: 0 }],
    }
  ]);


  const doubleEntryAccountRoute = `/double-entry-account/company/${selectedCompany?.id}`;

  const {
    data: accounts,
    isLoading: isLoadingAccounts,
    error: errorAccounts,
  } = useQuery<ApiResponse<DoubleEntryAccount[]>>({
    queryFn: async () => GenericApi.get<DoubleEntryAccount[]>(doubleEntryAccountRoute),
    queryKey: [DoubleEntryAccountQueryKey],
  });

  const {
    data: currencies,
    isLoading: isLoadingCurrencies,
    error: errorCurrencies,
  } = useQuery<ApiResponse<Currency[]>>({
    queryFn: async () => GenericApi.get<Currency[]>(ApiRoutes.CURRENCIES),
    queryKey: [CurrencyQueryKey],
  });


  const {
    data: journals,
    isLoading: isLoadingJournals,
    error: errorJournals,
  } = useQuery<ApiResponse<DoubleEntryJournalView[]>>({
    queryFn: async () => GenericApi.getById<DoubleEntryJournalView[]>(ApiRoutes.JOURNALS,selectedPage!),
    queryKey: [JournalEntryQueryKey, selectedPage],
  });
  
  console.log(journals, "journals");

  const DoubleEntryAccountTaxRoute = `/double-entry-account-tax/company/${selectedCompany?.id}`;

  const {
    data: doubleEntryTax,
  } = useQuery<ApiResponse<DoubleEntryTax[]>>({
    queryFn: async () => GenericApi.get<DoubleEntryTax[]>(DoubleEntryAccountTaxRoute),
    queryKey: [DoubleEntryTaxQueryKey],
  })

  console.log(doubleEntryTax, "doubleEntryTax");


  const addJournalMutation = useMutation<
    ApiResponse<DoubleEntryJournal>,
    ApiError<ValidationErrors>,
    DoubleEntryJournalCreateRequest
  >({
    mutationFn: (data) => GenericApi.post<DoubleEntryJournal>(ApiRoutes.JOURNALS, data),
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: [JournalEntryQueryKey] });
      showSnackBar("Journal created", "success", { vertical: "top", horizontal: "right" }, 3000);
      setRows([{
        journalNumber: "",
        type: "",
        date: "",
        documentNumber: "",
        description: "",
        currency: "",
        vat: "",
        pairs: [{ debit_account: "", credit_account: "", debit: 0, credit: 0 }],
      }]);
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      handleAxiosError(error);
      showSnackBar("Error creating Journal Entry", "error", { vertical: "top", horizontal: "right" }, 3000);
    },
  });

  const renderJournalEntries = () => {
    return journals?.data?.map((journal) => (
      <tr key={journal.id}>
        <td className="px-6 py-3 text-center">{journal.journal_number}</td>
        <td className="px-6 py-3 text-center">{journal.date}</td>
        <td className="px-6 py-3 text-center">{journal.debit.account.name}</td>
        <td className="px-6 py-3 text-center">{journal.credit.account.name}</td>
        <td className="px-6 py-3 text-center">{journal.document_number}</td>
        <td className="px-6 py-3 text-center">{journal.description}</td>
        <td className="px-6 py-3 text-center">{journal.debit.amount}</td>
        <td className="px-6 py-3 text-center">{journal.credit.amount}</td>
        <td className="px-6 py-3 text-center">{journal.currency}</td>
      </tr>
    ));
  }

  const debitSubtotal = rows.reduce((debitSum, row) => {
    return debitSum + row.pairs.reduce((sum, pair) => sum + (pair.debit || 0), 0);
  }, 0);
  
  const creditSubtotal = rows.reduce((creditSum, row) => {
    return creditSum + row.pairs.reduce((sum, pair) => sum + (pair.credit || 0), 0);
  }, 0);
  
  const total = debitSubtotal - creditSubtotal;
  

  const handleRowInputChange = (
    rowIndex: number,
    field: keyof typeof rows[0],
    value: string | number
  ) => {
    const updatedRows = rows.map((row, i) =>
      i === rowIndex
        ? { ...row, [field]: value }
        : row
    );
    setRows(updatedRows);
  };

  const handlePairInputChange = (
    rowIndex: number,
    pairIndex: number,
    field: keyof Pair,
    value: string | number
  ) => {
    const updatedRows = rows.map((row, i) =>
      i === rowIndex
        ? {
            ...row,
            pairs: row.pairs.map((pair, j) =>
              j === pairIndex ? { ...pair, [field]: value } : pair
            ),
          }
        : row
    );
    setRows(updatedRows);
  };

  const addNewLine = () => {
    setRows([
      ...rows,
      {
        journalNumber: "",
        type: "",
        date: "",
        documentNumber: "",
        description: "",
        currency: "",
        vat: "",
        pairs: [{ debit_account: "", credit_account: "", debit: 0, credit: 0 }],
      },
    ]);
  };

  const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
    if (isAxiosError<FormError>(error) || isAxiosError<ValidationErrors>(error)) {
      console.log(error);
    } else {
      console.log(error);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (!selectedCompany) {
      showSnackBar("Please select a company", "error", { vertical: "top", horizontal: "right" }, 3000);
      return;
    }
    
  
    const journalEntries = rows.flatMap(row =>
      row.pairs.map(pair => ({
        company_id: selectedCompany.id,
        journal_number: row.journalNumber,
        date: row.date,
        page_id: selectedPage,
        description: row.description,
        document_number: row.documentNumber,
        currency: row.currency,
        vat: row.vat,
        type: row.type,
        debit: {
          account_id: pair.debit_account,
          amount: pair.debit,
        },
        credit: {
          account_id: pair.credit_account,
          amount: pair.credit,
        },
      }))
    );
  
    const payload = journalEntries;
    console.log(payload, "payload");
    addJournalMutation.mutate(payload);
  }
  

  if (errorAccounts || errorCurrencies || errorJournals) {
    console.error(errorAccounts || errorCurrencies);
    return <FallbackPage onRetry={() => window.location.reload()} />;
  }

  if (isLoadingAccounts || isLoadingCurrencies || isLoadingJournals) {
    return <LoadingPage />;
  }

  return (
    <section className="mt-20 bg-gray-50 dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 relative shadow-md rounded-lg overflow-hidden">
        <div className="py-3 px-4 mx-auto">
          <div className="w-full flex justify-between items-center">
          <h2 className="mb-8 text-xl font-bold text-gray-900 dark:text-white">
            Create Journal Entry
          </h2>
          </div>
         
          <form onSubmit={onSubmit} className="space-y-4 lg:space-y-6">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
    <tr>
      <th className="px-4 py-3 text-center">Journal Number</th>
      <th className="px-4 py-3 text-center">Type</th>
      <th className="px-4 py-3 text-center">Date</th>
      <th className="px-4 py-3 text-center">Debit Account</th>
      <th className="px-4 py-3 text-center">Credit Account</th>
      <th className="px-4 py-3 text-center">Debit</th>
      <th className="px-4 py-3 text-center">Credit</th>
      <th className="px-4 py-3 text-center">Document Number</th>
      <th className="px-4 py-3 text-center">VAT</th>
      <th className="px-4 py-3 text-center">Description</th>
      <th className="px-4 py-3 text-center">Currency</th>
    </tr>
  </thead>
  <tbody>
    {rows.map((row, rowIndex) => (
      <tr key={rowIndex} className="bg-white dark:bg-gray-800 dark:border-gray-700">
        <td className="p-1">
          <input
            type="text"
            value={row.journalNumber}
            onChange={(e) => handleRowInputChange(rowIndex, 'journalNumber', e.target.value)}
            placeholder="No"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
          />
        </td>
        <td className="p-1">
          <input
            type="text"
            value={row.type}
            onChange={(e) => handleRowInputChange(rowIndex, 'type', e.target.value)}
            placeholder="Type"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
          />
        </td>
        <td className="p-1">
          <input
            type="date"
            value={row.date}
            onChange={(e) => handleRowInputChange(rowIndex, 'date', e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
          />
        </td>
        
        {row.pairs.map((pair, pairIndex) => (
          <React.Fragment key={pairIndex}>
            <td className="p-1">
              <select
                value={pair.debit_account}
                onChange={(e) => handlePairInputChange(rowIndex, pairIndex, "debit_account", e.target.value)}
                style={{ width: "9.375rem" }}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block p-2.5 dark:bg-gray-700 dark:border-gray-600"
              >
                <option value="" disabled>Select Account</option>
                {accounts?.data?.map((acc) => (
                  <option key={acc.id} value={acc.id}>{acc.name}</option>
                ))}
              </select>
            </td>
            <td className="p-1">
              <select
                value={pair.credit_account}
                onChange={(e) => handlePairInputChange(rowIndex, pairIndex, "credit_account", e.target.value)}
                style={{ width: "9.375rem" }}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block p-2.5 dark:bg-gray-700 dark:border-gray-600"
              >
                <option value="" disabled>Select Account</option>
                {accounts?.data?.map((acc) => (
                  <option key={acc.id} value={acc.id}>{acc.name}</option>
                ))}
              </select>
            </td>
            <td className="p-1">
              <input
                type="number"
                value={pair.debit}
                onChange={(e) => handlePairInputChange(rowIndex, pairIndex, "debit", Number(e.target.value))}
                min="0"
                step="any"
                placeholder="Debit"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-24 p-2.5 dark:bg-gray-700 dark:border-gray-600"
              />
            </td>
            <td className="p-1">
              <input
                type="number"
                value={pair.credit}
                onChange={(e) => handlePairInputChange(rowIndex, pairIndex, "credit", Number(e.target.value))}
                min="0"
                step="any"
                placeholder="Credit"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-24 p-2.5 dark:bg-gray-700 dark:border-gray-600"
              />
            </td>
          </React.Fragment>
        ))}
        
        <td className="p-1">
          <input
            type="text"
            value={row.documentNumber}
            onChange={(e) => handleRowInputChange(rowIndex, 'documentNumber', e.target.value)}
            placeholder="No"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
          />
        </td>
        <td className="p-1">
          <select value={row.vat} 
          onChange={(e) => handleRowInputChange(rowIndex, 'vat', e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block p-2.5 dark:bg-gray-700 dark:border-gray-600"
          >
            <option value="" disabled>VAT</option>
            {doubleEntryTax?.data?.map((double_entry_account_tax) => (
                  <option key={double_entry_account_tax.id} value={double_entry_account_tax.id}>{double_entry_account_tax.id}</option>
                ))}
            </select>
        </td>
        <td className="p-1">
          <input
            type="text"
            value={row.description}
            onChange={(e) => handleRowInputChange(rowIndex, 'description', e.target.value)}
            placeholder="Description"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
          />
        </td>
        <td className="p-1">
          <select
            value={row.currency}
            onChange={(e) => handleRowInputChange(rowIndex, 'currency', e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
          > 
            <option value="" disabled>CCY</option>
            {currencies?.data?.map((curr, index) => (
              <option key={index} value={curr.currency}>{curr.currency}</option>
            ))}
          </select>
        </td>
      </tr>
    ))}
    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
      <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></th>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Subtotal</td>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{debitSubtotal}</td>
      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{creditSubtotal}</td>
    </tr>
    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
      <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></th>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Total</td>
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{total.toFixed(2)}</td>
      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{total.toFixed(2)}</td>
    </tr>
  </tbody>
</table>

            <div className="flex gap-5">
              <button
                type="button"
                onClick={addNewLine}
                className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Add Line
              </button>
              <button
                type="submit"
                className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Submit Entry
              </button>
            </div>
          </form>
        </div>

        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-4 py-3 text-center">Journal No</th>
              <th className="px-4 py-3 text-center">Date</th>
              <th className="px-4 py-3 text-center">Debit Account</th>
              <th className="px-4 py-3 text-center">Credit Account</th>
              <th className="px-4 py-3 text-center">Document Number</th>
              <th className="px-4 py-3 text-center">Description</th>
              <th className="px-4 py-3 text-center">Debit</th>
              <th className="px-4 py-3 text-center">Credit</th>
              <th className="px-4 py-3 text-center">Currency</th>
              {/* <th className="px-4 py-3"></th> */}
            </tr>
          </thead>
          <tbody>
              {
                renderJournalEntries()
              }
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default JournalItem;



