import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { ApiError, ApiResponse } from "@/types/Api";
import { CreateReportRequest } from "@/types/Reports";
import { FormError, ValidationErrors } from "@/types/ValidationError";
import GenericApi from "@/api/genericApi";
import CustomLogger from "@/utils/CustomLogger";
import { AxiosError, isAxiosError } from "axios";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

const Reports = () => {

  const [reportDate, setReportDate] = useState("");
  const [basis, setBasis] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [specificDay, setSpecificDay] = useState("");
  const [reportType, setReportType] = useState("");
  const [accountStart, setAccountStart] = useState("");
  const [accountEnd, setAccountEnd] = useState("");


  const selectedCompany = useSelector((state: RootState) => state.company.selectedCompany);

  const selectReportRoute = () => {
    switch (reportType) {
      case "trialbalance":
        return `/reports/trial-balance-two-columns`;
      case "journal":
        return `/reports/journal-listing-purchases`;
      case "trialbalancesixcolumns":
        return `/reports/trial-balance-six-columns`;
      case "journal-listing-sales":
        return `/reports/journal-listing-sales`;
      case "trade-detailed-ledger-report":
        return `/reports/trade-detailed-ledger-report`;
      case "trade-receivables-ledger-report":
        return `/reports/trade-receivables-ledger-report`;
      case "detail-leger-multicurrency":
        return `/reports/detail-ledger-multicurrency`;
      case "vat-analysis":
        return `/reports/vat-analysis`; 
      case "vat-analytical":
        return `/reports/vat-analytical`;
      case "profit-loss":
        return `/reports/account-balance`;
      default:
        return "";
  }
}

  const selectFileNames = () => {
    switch (reportType) {
      case "trialbalance":
        return `TrialBalanceReport-${new Date().toISOString()}.pdf`;
      case "journal":
        return `JournalListingReport-${new Date().toISOString()}.pdf`;
      case "trialbalancesixcolumns":
        return `TrialBalanceSixColumnsReport-${new Date().toISOString()}.pdf`;
      case "journal-listing-sales":
        return `JournalListingSalesReport-${new Date().toISOString()}.pdf`;
      case "trade-detailed-ledger-report":
        return `TradeDetailedLedgerReport-${new Date().toISOString()}.pdf`;
      case "trade-receivables-ledger-report":
        return `TradeReceivablesLedgerReport-${new Date().toISOString()}.pdf`;
      case "detail-leger-multicurrency":
        return `DetailLedgerMulticurrencyReport-${new Date().toISOString()}.pdf`;
      case "vat-analysis":
        return `VatAnalysisReport-${new Date().toISOString()}.pdf`;
      case "vat-analytical":
        return `VatAnalyticalReport-${new Date().toISOString()}.pdf`;
      case "profit-loss":
        return `ProfitLossReport-${new Date().toISOString()}.pdf`;
      default:
        return "";
  }
}


  const route = selectReportRoute();

  const addReport = useMutation<ApiResponse<BlobPart>,
  ApiError<ValidationErrors>,
  CreateReportRequest
>({
  mutationFn: (data) => GenericApi.post<BlobPart>(route, data, 'blob'),
  onSuccess: async ({ data }) => {
    CustomLogger.log(data);
    const filename = selectFileNames();
    if(data){
      const linkUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = linkUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }

  },
  onError: async ({ error }) => {
    CustomLogger.error(error);
    handleAxiosError(error);
  },
});

const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
  if (isAxiosError<FormError>(error) || isAxiosError<ValidationErrors>(error)) {
    console.log(error);
  } else {
    console.log(error);
  }
};

  const handleSubmit = (event:React.SyntheticEvent) => {
    event.preventDefault();
    const sDate = new Date(startDate).getTime();
    const eDate = new Date(endDate).getTime();

    if(sDate > eDate){
      alert("Start Date cannot be greater than End Date");
      return;
    }

    if(reportDate === "specific_day"){
     setStartDate(specificDay);
     setEndDate(specificDay);
    }
    else if(reportDate === "range" ){
      setStartDate(startDate);
      setEndDate(endDate);
    }

    if(selectedCompany !== null){
      const company_id = selectedCompany.id;
      const data = {
        selected_company_id:company_id,
        basis,
        start_date: reportDate === "range" ? startDate : specificDay,
        end_date: reportDate === "range" ? endDate : specificDay,
        account_start: reportType === 'detail-leger-multicurrency' ? accountStart : "",
        account_end: reportType === 'detail-leger-multicurrency' ? accountEnd : "",
      };
      console.log("Form Data: ", data);
      addReport.mutate(data);
    }
  };

  return (
    <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16 mt-24">
      <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
        Generate Reports
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4 lg:space-y-6">
        <div>
          <label
            htmlFor="report_type"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Report Type <span className="text-red-600">*</span>
          </label>
          <select
            id="report_type"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            required
            className="bg-gray-50 mb-3 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="" disabled hidden>
              Select Report Type
            </option>
            <option value="trialbalance">Trial Balance Two Columns</option>
            <option value="journal">Journal Listing Purchases</option>
            <option value="trialbalancesixcolumns">Trial Balance Six Columns</option>
            <option value="journal-listing-sales">Journal Listing Sales</option>
            <option value="trade-detailed-ledger-report">Trade Detailed Ledger</option>
            <option value="trade-receivables-ledger-report">Trade Receivables Ledger</option>
            <option value="detail-leger-multicurrency">Detail Ledger Multicurrency</option>
            <option value="vat-analysis">Vat Analysis</option>
            <option value="vat-analytical">Vat Analytical</option>
            <option value="profit-loss">Profit Loss</option>

          </select>
          <label
            htmlFor="basis"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Basis <span className="text-red-600">*</span>
          </label>
          <select
            id="basis"
            value={basis}
            onChange={(e) => setBasis(e.target.value)}
            required
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="" disabled hidden>
              Basis
            </option>
            <option value="cash">Cash</option>
            <option value="accrual">Accrual</option>
          </select>
        </div>

        <div>
          <label
            htmlFor="report_date"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Report Date
          </label>
          <select
            id="report_date"
            value={reportDate}
            onChange={(e) => setReportDate(e.target.value)}
            required
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="">None</option>
            <option value="range">Range</option>
            <option value="specific_day">Specific Day</option>
          </select>
        </div>

        {reportDate === "range" && (
          <div className="space-y-4 lg:space-y-6">
            <div>
              <label
                htmlFor="start_date"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Start Date
              </label>
              <input
                type="date"
                id="start_date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div>
              <label
                htmlFor="end_date"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                End Date
              </label>
              <input
                type="date"
                id="end_date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
        )}

        {reportDate === "specific_day" && (
          <div className="space-y-4 lg:space-y-6">
            <div>
              <label
                htmlFor="specific_day"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Specific Day
              </label>
              <input
                type="date"
                id="specific_day"
                value={specificDay}
                onChange={(e) => setSpecificDay(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
  )}

        {reportType === 'detail-leger-multicurrency' ?  
        <div>
          <div>
              <label
                htmlFor="specific_day"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Account Start
              </label>
              <input
                type="text"
                id="account_start"
                value={accountStart}
                onChange={(e) => setAccountStart(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mt-3">
              <label
                htmlFor="specific_day"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Account End
              </label>
              <input
                type="text"
                id="account_end"
                value={accountEnd}
                onChange={(e) => setAccountEnd(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
           : ""}
          

        <button
          type="submit"
          className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
        >
          Generate Report
        </button>
      </form>
    </div>
  );
};

export default Reports;

