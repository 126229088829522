// import GenericApi from "@/api/genericApi";
// import { RootState } from "@/store";
// import { ApiResponse } from "@/types/Api";
// import { Payslip } from "@/types/PaySlip";
// import { useQuery } from "@tanstack/react-query";
// import  { useState } from "react";
// import { useSelector } from "react-redux";

// const PayrollPage = () => {
//   const [showCalculations, setShowCalculations] = useState(false);
//   const selectedCompany = useSelector((state: RootState) => state.company.selectedCompany);
//   const handleCalculate = () => {
//     setShowCalculations(true);
//   };

//   const payslipRoute = selectedCompany ? `/payslips/${selectedCompany.id}` : "/not-found";

//   const { data: payslips } = useQuery<ApiResponse<Payslip[]>>({
//     queryKey: ["payslips"],
//     queryFn:async () => GenericApi.get<Payslip[]>(payslipRoute)
//   })

//   console.log(payslips?.data);

//   return (
//     <div className="grid grid-cols-3">
//       <div className="flex flex-col items-center gap-10 bg-white shadow-lg rounded-lg p-8 w-full">
//         <h1 className="text-2xl w-full text-left font-bold mb-6">Payroll</h1>

//         <div className="w-full mb-4">
//           <label className="block text-gray-700 text-sm font-bold mb-2">
//             Name
//           </label>
//           <div className="flex space-x-4">
//             <input
//               className="border rounded-md w-full p-2"
//               type="text"
//               placeholder="Employee First Name"
//               value={firstName}
//             />
//             <input
//               className="border rounded-md w-full p-2"
//               type="text"
//               placeholder="Employee Last Name"
//               value={lastName}
//             />
//           </div>
//         </div>

//         <div className="w-full mb-6">
//           <div className="grid grid-cols-3 gap-4 text-left">
//             <div>
//               <label className="block text-gray-700 text-sm font-bold mb-5">
//                 Salary
//               </label>
//               <label className="block text-gray-700 text-sm font-bold mb-5">
//                 GROSS
//               </label>
//               <label className="block text-gray-700 text-sm font-bold">
//                 NET
//               </label>
//             </div>
//             <div>
//               <label className="block text-gray-500 text-sm mb-1">
//                 Monthly
//               </label>
//               <div className="border p-2 rounded-md text-center">
//                 ${grossMonthly.toFixed(2)}
//               </div>
//               <div className="border p-2 rounded-md text-center">
//                 ${netMonthly.toFixed(2)}
//               </div>
//             </div>
//             <div>
//               <label className="block text-gray-500 text-sm mb-1">
//                 Annually
//               </label>
//               <div className="border p-2 rounded-md text-center">
//                 ${grossAnnual.toFixed(2)}
//               </div>
//               <div className="border p-2 rounded-md text-center">
//                 ${netAnnual.toFixed(2)}
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="w-full flex justify-end">
//           <button
//             onClick={handleCalculate}
//             className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md"
//           >
//             Calculate
//           </button>
//         </div>
//       </div>

//       {showCalculations && ( // Afișăm conținutul numai după apăsarea butonului
//        <div className="p-8 col-span-2">
//          {/* Contributions Section */}
//          <div className="mb-8">
//            <div className="flex w-full justify-between">
//            <h2 className="text-2xl font-bold mb-4 w-full">Contributions</h2>
//            <h2 className="text-2xl font-bold mb-4 w-full text-center">Employer</h2>
//            <h2 className="text-2xl font-bold mb-4 w-full text-center">Employee</h2>
//            </div>
//            <table className="min-w-full table-auto border-collapse border border-gray-200">
//              <thead className="bg-blue-100">
//                <tr>
//                  <th className="border border-gray-300 p-2">SI Contributions</th>
//                  <th className="border border-gray-300 p-2 text-center">
//                    Rate
//                  </th>
//                  <th className="border border-gray-300 p-2 text-center">
//                    Amount
//                  </th>
//                  <th className="border border-gray-300 p-2 text-center">
//                    Rate
//                  </th>
//                  <th className="border border-gray-300 p-2 text-center">
//                    Amount
//                  </th>
//                </tr>
//              </thead>
//              <tbody>
//                <tr>
//                  <td className="border border-gray-300 p-2">SI Employer</td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    8.80%
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    €220.00
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    8.80%
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    €220.00
//                  </td>
//                </tr>
//                <tr>
//                  <td className="border border-gray-300 p-2">Cohesion Fund</td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    2.00%
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    €50.00
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    2.00%
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    €50.00
//                  </td>
//                </tr>
//                <tr>
//                  <td className="border border-gray-300 p-2">
//                    Industrial Training
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    0.50%
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    €12.50
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    0.50%
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    €12.50
//                  </td>
//                </tr>
//                <tr>
//                  <td className="border border-gray-300 p-2">Providence Fund</td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    1.20%
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    €30.00
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    1.20%
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    €30.00
//                  </td>
//                </tr>
//                <tr>
//                  <td className="border border-gray-300 p-2">GESY Employers</td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    2.90%
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    €72.50
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    2.90%
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">
//                    €72.50
//                  </td>
//                </tr>
//                <tr className="font-bold">
//                  <td className="border border-gray-300 p-2">Total</td>
//                  <td className="border border-gray-300 p-2 text-right" colSpan={2}>
//                    €385.00
//                  </td>
//                  <td className="border border-gray-300 p-2 text-right" colSpan={2}>
//                    €286.25
//                  </td>
//                </tr>
//              </tbody>
//            </table>
//            <div className="text-right mt-2 font-bold">Payable: €671.25</div>
//          </div>

//          {/* Tax Calculation Section */}
//          <div className="mb-8 flex flex-col gap-10">
//            <h2 className="text-2xl font-bold mb-4">Tax Calculation</h2>
//            <div className="grid grid-cols-2 gap-2">
//              <div className="font-bold">Less subscriptions</div>
//              <div className="text-left">€0.00</div>
//              <div className="font-bold">Less SI</div>
//              <div className="text-left">€2,640.00</div>
//              <div className="font-bold">Less GESY</div>
//              <div className="text-left">€795.00</div>
//              <div className="font-bold"></div>
//              <div className="text-left font-bold">€3,435.00</div>
//            </div>
//            <div className="grid grid-cols-2 gap-2">
//              <div className="font-bold">Less Insurances <span className="font-normal">(*Capped to 1/5 of  taxable profit)</span></div>
//              <div></div>
//              <div className="font-bold">Life insurance</div>
//              <div className="text-left">€0.00</div>
//              <div className="font-bold">Family</div>
//              <div className="text-left">€0.00</div>
//              <div className="font-bold">Medical</div>
//              <div className="text-left">€0.00</div>
//              <div className="font-bold"></div>
//              <div className="text-left font-bold">€3,435.00</div>
//            </div>
//            <div className="grid grid-cols-2 gap-2">
//              <div className="font-bold">Tax Free</div>
//              <div className="text-left">€19,500.00</div>
//              <div className="font-bold"></div>
//              <div className="text-left font-bold">€7,065.00</div>
//            </div>
//          </div>

//          {/* Tax Payable Section */}
//          <div className="mb-8">
//            <h2 className="text-2xl font-bold mb-4">Tax Payable</h2>
//            <table className="min-w-full table-auto border-collapse border border-gray-200">
//              <thead className="bg-blue-100">
//                <tr>
//                  <th className="border border-gray-300 p-2">Tax Payable</th>
//                  <th className="border border-gray-300 p-2">Tax Rate</th>
//                  <th className="border border-gray-300 p-2">Taxable Profit</th>
//                  <th className="border border-gray-300 p-2">Accumulated Tax</th>
//                </tr>
//              </thead>
//              <tbody>
//                <tr>
//                  <td className="border border-gray-300 p-2">€0 - €19,500</td>
//                  <td className="border border-gray-300 p-2 text-center">0%</td>
//                  <td className="border border-gray-300 p-2 text-right">€ 19,500.00</td>

//                  <td className="border border-gray-300 p-2 text-right">€0.00</td>
//                </tr>
//                <tr>
//                  <td className="border border-gray-300 p-2">
//                    €19,500 - €28,000
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">20%</td>
//                  <td className="border border-gray-300 p-2 text-right">€0.00</td>

//                  <td className="border border-gray-300 p-2 text-right">€0.00</td>
//                </tr>
//                <tr>
//                  <td className="border border-gray-300 p-2">
//                    €28,000 - €36,300
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">25%</td>
//                  <td className="border border-gray-300 p-2 text-right">€0.00</td>

//                  <td className="border border-gray-300 p-2 text-right">€0.00</td>
//                </tr>
//                <tr>
//                  <td className="border border-gray-300 p-2">
//                    €36,300 - €60,000
//                  </td>
//                  <td className="border border-gray-300 p-2 text-center">30%</td>
//                  <td className="border border-gray-300 p-2 text-right">€0.00</td>

//                  <td className="border border-gray-300 p-2 text-right">€0.00</td>
//                </tr>
//                <tr>
//                  <td className="border border-gray-300 p-2">Above €60,000</td>
//                  <td className="border border-gray-300 p-2 text-center">35%</td>
//                  <td className="border border-gray-300 p-2 text-right">€0.00</td>

//                  <td className="border border-gray-300 p-2 text-right">€0.00</td>
//                </tr>
//              </tbody>
//            </table>
//            <div className="mt-2 grid grid-cols-2">
//              <p className="font-bold">Total Tax for the year:</p>
//              <p className="text-right font-bold">€0.00</p>
//              <p className="font-bold">Less: Provisional Tax Paid</p>
//              <p className="text-right">Payeer</p>
//              <p className="font-bold">Total Tax Payable:</p>
//              <p className="text-right font-bold">€0.00</p>
//            </div>
//          </div>

//          {/* Generate Payslip Button */}
//          <div className="text-center">
//            <button className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
//              Generate Payslip
//            </button>
//          </div>
//        </div>
//       )}
//     </div>
//   );
// };

// export default PayrollPage;

// import GenericApi from "@/api/genericApi";
// import { RootState } from "@/store";
// import { ApiResponse } from "@/types/Api";
// import { Payslip } from "@/types/PaySlip";
// import { useQuery } from "@tanstack/react-query";
// import { useState } from "react";
// import { useSelector } from "react-redux";

// const PayrollPage = () => {
//   const [showCalculations, setShowCalculations] = useState(false);
//   const selectedCompany = useSelector(
//     (state: RootState) => state.company.selectedCompany
//   );
//   const handleCalculate = () => {
//     setShowCalculations(true);
//   };

//   const payslipRoute = selectedCompany
//     ? `/payslip/${selectedCompany.id}`
//     : "/not-found";

//   const { data: payslips } = useQuery<ApiResponse<Payslip[]>>({
//     queryKey: ["payslips"],
//     queryFn: async () => GenericApi.get<Payslip[]>(payslipRoute),
//   });

//   const payslip = payslips?.data?.[0];

//   return (
//     <div className="grid grid-cols-3">
//       <div className="flex flex-col items-center gap-10 bg-white shadow-lg rounded-lg p-8 w-full">
//         <h1 className="text-2xl w-full text-left font-bold mb-6">Payroll</h1>

//         <div className="w-full mb-4">
//           <label className="block text-gray-700 text-sm font-bold mb-2">
//             Name
//           </label>
//           <div className="flex space-x-4">
//             <input
//               className="border rounded-md w-full p-2"
//               type="text"
//               placeholder="Employee First Name"
//               value={payslip?.employee_id || ""}
//               readOnly
//             />
//           </div>
//         </div>

//         <div className="w-full mb-6">
//           <div className="grid grid-cols-3 gap-4 text-left">
//             <div>
//               <label className="block text-gray-700 text-sm font-bold mb-5">
//                 Salary
//               </label>
//               <label className="block text-gray-700 text-sm font-bold mb-5">
//                 GROSS
//               </label>
//               <label className="block text-gray-700 text-sm font-bold">
//                 NET
//               </label>
//             </div>
//             <div>
//               <label className="block text-gray-500 text-sm mb-1">Monthly</label>
//               <div className="border p-2 rounded-md text-center">
//                 {payslip?.salary_details?.monthly_salary
//                   ? `€${parseFloat(payslip.salary_details.monthly_salary).toFixed(
//                       2
//                     )}`
//                   : "-"}
//               </div>
//               <div className="border p-2 rounded-md text-center">
//                 {payslip?.salary_details?.net_salary
//                   ? `€${parseFloat(payslip.salary_details.net_salary).toFixed(2)}`
//                   : "-"}
//               </div>
//             </div>
//             <div>
//               <label className="block text-gray-500 text-sm mb-1">Annually</label>
//               <div className="border p-2 rounded-md text-center">
//                 {payslip?.salary_details?.annual_salary
//                   ? `€${parseFloat(payslip.salary_details.annual_salary).toFixed(
//                       2
//                     )}`
//                   : "-"}
//               </div>
//               <div className="border p-2 rounded-md text-center">
//                 {payslip?.salary_details?.net_salary_annual
//                   ? `€${parseFloat(payslip.salary_details.net_salary_annual).toFixed(
//                       2
//                     )}`
//                   : "-"}
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="w-full flex justify-end">
//           <button
//             onClick={() => {
//               handleCalculate();
//             }}
//             className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md"
//           >
//             Calculate
//           </button>
//         </div>
//       </div>

//       {showCalculations && payslip && (
//         <div className="p-8 col-span-2">
//           <div className="mb-8">
//             <div className="flex w-full justify-between">
//               <h2 className="text-2xl font-bold mb-4 w-full">Contributions</h2>
//               <h2 className="text-2xl font-bold mb-4 w-full text-center">
//                 Employer
//               </h2>
//               <h2 className="text-2xl font-bold mb-4 w-full text-center">
//                 Employee
//               </h2>
//             </div>
//             <table className="min-w-full table-auto border-collapse border border-gray-200">
//               <thead className="bg-blue-100">
//                 <tr>
//                   <th className="border border-gray-300 p-2">Contribution</th>
//                   <th className="border border-gray-300 p-2 text-center">
//                     Employer Amount
//                   </th>
//                   <th className="border border-gray-300 p-2 text-center">
//                     Employee Amount
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className="border border-gray-300 p-2">SI Contributions</td>
//                   <td className="border border-gray-300 p-2 text-center">
//                     €{payslip?.employer_contributions?.si_employer_amount || "0.00"}
//                   </td>
//                   <td className="border border-gray-300 p-2 text-center">
//                     €{payslip?.employee_contributions?.si_employee_amount || "0.00"}
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="border border-gray-300 p-2">Cohesion Fund</td>
//                   <td className="border border-gray-300 p-2 text-center">
//                     €{payslip?.employer_contributions?.cohesion_fund_amount || "0.00"}
//                   </td>
//                   <td className="border border-gray-300 p-2 text-center">
//                     €{payslip?.employee_contributions?.cohesion_fund_employee_amount ||
//                       "0.00"}
//                   </td>
//                 </tr>
//                 <tr className="font-bold">
//                   <td className="border border-gray-300 p-2">Total</td>
//                   <td
//                     className="border border-gray-300 p-2 text-right"
//                     colSpan={2}
//                   >
//                     €{payslip?.total_contributions?.payable || "0.00"}
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>

//           <div className="mb-8">
//             <h2 className="text-2xl font-bold mb-4">Tax Calculation</h2>
//           </div>

//           <div className="text-center">
//             <button className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
//               Generate Payslip
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PayrollPage;

import GenericApi from "@/api/genericApi";
import { PaySlipQueryKey } from "@/config/constants/QueryKeys";
import { RootState } from "@/store";
import { ApiResponse } from "@/types/Api";
import { Payslip } from "@/types/PaySlip";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useSelector } from "react-redux";

const PayrollPage = () => {
  const [showCalculations, setShowCalculations] = useState(false);
  const selectedCompany = useSelector(
    (state: RootState) => state.company.selectedCompany
  );

  const handleCalculate = () => {
    setShowCalculations(true);
  };

  const payslipRoute = selectedCompany
    ? `/payslip/${selectedCompany.id}`
    : "/not-found";

  const { data: payslipData } = useQuery<ApiResponse<Payslip[]>>({
    queryKey: [PaySlipQueryKey],
    queryFn: async () => GenericApi.get<Payslip[]>(payslipRoute),
  });

  const payslip = payslipData?.data?.[0];

  return (
    <div className="grid grid-cols-3">
      <div className="flex flex-col items-center gap-10 bg-white shadow-lg rounded-lg p-8 w-full">
        <h1 className="text-2xl w-full text-left font-bold mb-6">Payroll</h1>

       <div className="flex gap-5">
       <div className="w-full mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            First Name
          </label>
          
          <div className="flex space-x-4">
            <input
              className="border rounded-md w-full p-2"
              type="text"
              placeholder="Employee First Name"
              value={payslip ? payslip.salary_details.first_name : ""}
              disabled
            />
          </div>

        </div>
        <div className="w-full mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            First Name
          </label>
          
          <div className="flex space-x-4">
          <input
              className="border rounded-md w-full p-2"
              type="text"
              placeholder="Employee Last Name"
              value={payslip ? payslip.salary_details.last_name : ""}
              disabled
            />
          </div>
        </div>
       </div>

        <div className="w-full mb-6">
          <div className="grid grid-cols-3 gap-4 text-left">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-5">
                Salary
              </label>
              <label className="block text-gray-700 text-sm font-bold mb-5">
                GROSS
              </label>
              <label className="block text-gray-700 text-sm font-bold">
                NET
              </label>
            </div>
            <div>
              <label className="block text-gray-500 text-sm mb-1">
                Monthly
              </label>
              <div className="border p-2 rounded-md text-center mb-2">
                ${payslip?.salary_details.monthly_salary || "0.00"}
              </div>
              <div className="border p-2 rounded-md text-center">
                ${payslip?.salary_details.net_salary || "0.00"}
              </div>
            </div>
            <div>
              <label className="block text-gray-500 text-sm mb-1">
                Annually
              </label>
              <div className="border p-2 rounded-md text-center mb-2">
                ${payslip?.salary_details.annual_salary || "0.00"}
              </div>
              <div className="border p-2 rounded-md text-center">
                ${payslip?.salary_details.net_salary_annual || "0.00"}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end">
          <button
            onClick={handleCalculate}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md"
          >
            Calculate
          </button>
        </div>
      </div>

      {showCalculations &&
        payslip && ( // Display content only after clicking the button
          <div className="p-8 col-span-2">
            {/* Contributions Section */}
            <div className="mb-8">
              <div className="flex w-full justify-between">
                <h2 className="text-2xl font-bold mb-4 w-full">
                  Contributions
                </h2>
                <h2 className="text-2xl font-bold mb-4 w-full text-center">
                  Employer
                </h2>
                <h2 className="text-2xl font-bold mb-4 w-full text-center">
                  Employee
                </h2>
              </div>
              <table className="min-w-full table-auto border-collapse border border-gray-200">
                <thead className="bg-blue-100">
                  <tr>
                    <th className="border border-gray-300 p-2">
                      SI Contributions
                    </th>
                    <th className="border border-gray-300 p-2 text-center">
                      Rate
                    </th>
                    <th className="border border-gray-300 p-2 text-center">
                      Amount
                    </th>
                    <th className="border border-gray-300 p-2 text-center">
                      Rate
                    </th>
                    <th className="border border-gray-300 p-2 text-center">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-300 p-2">SI Employer</td>
                    <td className="border border-gray-300 p-2 text-center">
                      8.80%
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      €{payslip.employer_contributions.si_employer_amount}
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      8.80%
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      €{payslip.employee_contributions.si_employee_amount}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 p-2">
                      Cohesion Fund
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      2.00%
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      €{payslip.employer_contributions.cohesion_fund_amount}
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      2.00%
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      €
                      {
                        payslip.employee_contributions
                          .cohesion_fund_employee_amount
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 p-2">
                      Industrial Training
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      0.50%
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      €
                      {
                        payslip.employer_contributions
                          .industrial_training_amount
                      }
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      0.50%
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      €
                      {
                        payslip.employee_contributions
                          .industrial_training_employee_amount
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 p-2">
                      Providence Fund
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      1.20%
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      €{payslip.employer_contributions.providence_fund_amount}
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      1.20%
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      €
                      {
                        payslip.employee_contributions
                          .providence_fund_employee_amount
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 p-2">
                      GESY Employers
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      2.90%
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      €{payslip.employer_contributions.gesy_employers_amount}
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      2.90%
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      €{payslip.employee_contributions.gesy_employee_amount}
                    </td>
                  </tr>
                  <tr className="font-bold">
                    <td className="border border-gray-300 p-2">Total</td>
                    <td
                      className="border border-gray-300 p-2 text-right"
                      colSpan={2}
                    >
                      €{payslip.total_contributions.employer}
                    </td>
                    <td
                      className="border border-gray-300 p-2 text-right"
                      colSpan={2}
                    >
                      €{payslip.total_contributions.employee}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="text-right mt-2 font-bold">
                Payable: €{payslip.total_contributions.payable}
              </div>
            </div>

            {/* Tax Calculation Section */}
            {/* <div className="mb-8 flex flex-col gap-10">
            <h2 className="text-2xl font-bold mb-4">Tax Calculation</h2>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <h3 className="font-bold mb-4">Less Subscription</h3>
                <div className="border p-4 rounded-md text-center">
                  €{payslip.tax_calculation.less_subscription.total_less}
                </div>
                <div className="mt-2 text-left">
                  <div>Less SI: €{payslip.tax_calculation.less_subscription.less_si}</div>
                  <div>Less GESY: €{payslip.tax_calculation.less_subscription.less_gesy}</div>
                </div>
              </div>
              <div>
                <h3 className="font-bold mb-4">Less Insurances</h3>
                <div className="border p-4 rounded-md text-center">
                  €{payslip.tax_calculation.less_insurances.total_less_insurances}
                </div>
                <div className="mt-2 text-left">
                  <div>Life Insurance: €{payslip.tax_calculation.less_insurances.life_insurance}</div>
                  <div>Family: €{payslip.tax_calculation.less_insurances.family}</div>
                  <div>Medical: €{payslip.tax_calculation.less_insurances.medical}</div>
                </div>
              </div>
              <div>
                <h3 className="font-bold mb-4">Tax Details</h3>
                <div className="border p-4 rounded-md text-center">
                  €{payslip.tax_total}
                </div>
                <div className="mt-2 text-left">
                  <div>Taxable Income: €{payslip.tax_details.taxable_income}</div>
                  <div>Tax Free: €{payslip.tax_details.tax_free}</div>
                </div>
              </div>
            </div>
          </div> */}
            {/* <div className="mb-8 flex flex-col gap-10">
           <h2 className="text-2xl font-bold mb-4">Tax Calculation</h2>
           <div className="grid grid-cols-2 gap-2">
              <div className="font-bold">Less subscriptions</div>
           <div className="text-left">€0.00</div>
             <div className="font-bold">Less SI</div>
             <div className="text-left">€2,640.00</div>
             <div className="font-bold">Less GESY</div>
             <div className="text-left">€795.00</div>
             <div className="font-bold"></div>
             <div className="text-left font-bold">€3,435.00</div>
           </div>
           <div className="grid grid-cols-2 gap-2">
             <div className="font-bold">Less Insurances <span className="font-normal">(*Capped to 1/5 of  taxable profit)</span></div>
             <div></div>
             <div className="font-bold">Life insurance</div>
             <div className="text-left">€0.00</div>
             <div className="font-bold">Family</div>
             <div className="text-left">€0.00</div>
             <div className="font-bold">Medical</div>
             <div className="text-left">€0.00</div>
             <div className="font-bold"></div>
             <div className="text-left font-bold">€3,435.00</div>
           </div>
           <div className="grid grid-cols-2 gap-2">
             <div className="font-bold">Tax Free</div>
             <div className="text-left">€19,500.00</div>
             <div className="font-bold"></div>
             <div className="text-left font-bold">€7,065.00</div>
           </div>
         </div> */}
            <div className="mb-8 flex flex-col gap-10">
              <h2 className="text-2xl font-bold mb-4">Tax Calculation</h2>
              <div className="grid grid-cols-2 gap-2">
                <div className="font-bold">Less subscriptions</div>
                <div className="text-left">
                  €{payslip.tax_calculation.less_subscription.less_subscription}
                </div>
                <div className="font-bold">Less SI</div>
                <div className="text-left">
                  €{payslip.tax_calculation.less_subscription.less_si}
                </div>
                <div className="font-bold">Less GESY</div>
                <div className="text-left">
                  €{payslip.tax_calculation.less_subscription.less_gesy}
                </div>
                <div className="font-bold"></div>
                <div className="text-left font-bold">
                  €{payslip.tax_calculation.less_subscription.total_less}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="font-bold">
                  Less Insurances{" "}
                  <span className="font-normal">
                    (*Capped to 1/5 of taxable profit)
                  </span>
                </div>
                <div></div>
                <div className="font-bold">Life insurance</div>
                <div className="text-left">
                  €{payslip.tax_calculation.less_insurances.life_insurance}
                </div>
                <div className="font-bold">Family</div>
                <div className="text-left">
                  €{payslip.tax_calculation.less_insurances.family}
                </div>
                <div className="font-bold">Medical</div>
                <div className="text-left">
                  €{payslip.tax_calculation.less_insurances.medical}
                </div>
                <div className="font-bold"></div>
                <div className="text-left font-bold">
                  €
                  {
                    payslip.tax_calculation.less_insurances
                      .total_less_insurances
                  }
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="font-bold">Tax Free</div>
                <div className="text-left">€{payslip.tax_free}</div>
                <div className="font-bold"></div>
                <div className="text-left font-bold">€{payslip.tax_total}</div>
              </div>
            </div>

            {/* Tax Brackets Section */}
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-4">Tax Brackets</h2>
              <table className="min-w-full table-auto border-collapse border border-gray-200">
                <thead className="bg-blue-100">
                  <tr>
                    <th className="border border-gray-300 p-2">Lower Bound</th>
                    <th className="border border-gray-300 p-2">Upper Bound</th>
                    <th className="border border-gray-300 p-2">Rate</th>
                    <th className="border border-gray-300 p-2">
                      Taxable Profit
                    </th>
                    <th className="border border-gray-300 p-2">Accumulled Tax</th>
                  </tr>
                </thead>
                <tbody>
                  {payslip.tax_details.brackets.map((bracket, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 p-2">
                        {bracket.lower_bound}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {bracket.upper_bound !== null
                          ? bracket.upper_bound
                          : "Above 60000"}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {bracket.rate}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {bracket.taxable_income}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {bracket.tax}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="mt-2 text-right font-bold">
                Accumulated Tax Total: €
                {payslip.tax_details.accumulated_tax_total}
              </div>
              <div className="mt-2 text-right font-bold">
                Total Tax for the year: €
                {payslip.salary_details.payee}
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default PayrollPage;
